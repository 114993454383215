import combined1 from "../assets/combined1.jpg";
import combined2 from "../assets/combined2.jpg";
import combined3 from "../assets/combined3.jpg";
import amsterdam1 from "../assets/amsterdamtour1.jpg";
import amsterdam2 from "../assets/amsterdamtour2.jpg";
import amsterdam3 from "../assets/amsterdamtour3.jpg";
import mill1 from "../assets/mill1.jpg";
import mill2 from "../assets/mill2.jpg";
import mill3 from "../assets/mill3.jpg";
import century1 from "../assets/century1.jpeg";
import century2 from "../assets/century2.jpeg";
import century3 from "../assets/century3.jpeg";
import boat1 from "../assets/boat1.jpg";
import boat2 from "../assets/boat2.jpeg";
import boat3 from "../assets/boat3.jpg";
import flower1 from "../assets/flower1.jpg";
import flower2 from "../assets/flower2.jpg";
import flower3 from "../assets/flower3.jpg";
import canalboat1 from "../assets/canalboat1.jpg";
import canalboat2 from "../assets/canalboat2.jpg";
import canalboat3 from "../assets/canalboat3.jpg";
import bike1 from "../assets/bike1.jpg";
import bike2 from "../assets/bike2.jpg";
import bike3 from "../assets/tour2.jpeg";
import quest1 from "../assets/quest1.jpeg";
import quest2 from "../assets/quest2.jpg";
import quest3 from "../assets/quest3.jpg";
import nightamsterdam1 from "../assets/nightamsterdam1.jpg";
import nightamsterdam2 from "../assets/nightamsterdam2.jpg";
import nightamsterdam3 from "../assets/nightamsterdam3.jpeg";
import tasty1 from "../assets/tasty1.jpg";
import tasty2 from "../assets/tasty2.jpg";
import tasty3 from "../assets/tasty3.jpg";
import tasting1 from "../assets/tasting.jpg";
import tasting2 from "../assets/tasting1.jpg";
import tasting3 from "../assets/tasting2.jpg";
import vangogh1 from "../assets/vangogh1.jpeg";
import vangogh2 from "../assets/vangogh2.jpeg";
import vangogh3 from "../assets/vangogh3.jpeg";
import delft1 from "../assets/delft1.jpeg";
import delft2 from "../assets/delft2.jpeg";
import delft3 from "../assets/delft3.jpeg";
import rotterdam1 from "../assets/rotterdam1.jpg";
import rotterdam2 from "../assets/rotterdam2.jpg";
import rotterdam3 from "../assets/rotterdam3.jpg";
import denhaag1 from "../assets/denhaag3.jpg";
import denhaag2 from "../assets/denhaag2.png";
import denhaag3 from "../assets/denhaag1.jpeg";

const cardData = [
	{
		title: "Хотите всё сразу? Комбинированный Тур",
		text: [
			" Прогулка по старому городу или йорданскому району - 2 часа.",
			" Фотосессия в национальной одежде.",
		],
		images: [combined1, combined2, combined3],
		additionalInfo: (
			<div>
				<p>
					<strong>Комбинированный тур по Амстердаму:</strong> уникальные
					впечатления для вашей группы
				</p>

				<p>
					Этот тур — идеальный способ познакомиться с Амстердамом с самых разных
					сторон! Мы начнем с увлекательной прогулки по одной из самых
					атмосферных частей города — старому городу или живописному Йорданскому
					району. В течение 2 часов вы насладитесь историей, архитектурой и
					атмосферой этих уникальных кварталов.
				</p>

				<p>
					Но это только начало! Вас ждёт возможность переодеться в традиционную
					национальную одежду, чтобы почувствовать дух прошлого и сделать
					невероятно красивые фотографии на фоне живописной деревенской
					атмосферы. После прогулки нас будет ждать вкусный перекус — свежая
					селёдка, как в лучших традициях Нидерландов.
				</p>

				<p>
					Затем мы отправимся на приватную лодку, чтобы увидеть Амстердам с
					воды. Город, раскинувшийся на каналах, раскрывает свою настоящую
					красоту с лодки, и это впечатление трудно передать словами. Вы можете
					взять с собой любимые напитки и закуски, чтобы наслаждаться
					путешествием, а если хотите, мы украсим лодку по вашему желанию —
					идеальный способ сделать ваш тур ещё более незабываемым! Лодка
					полностью забронирована для вашей группы (до 8 человек), и самое
					главное — стоимость тура указана за всю группу, а не за каждого
					человека.
				</p>

				<p>
					И для самых любознательных у нас есть дополнительная опция: визит в
					дом на воде, расположенный прямо на канале. Вы сможете заглянуть в
					уникальный мир жизни на воде и увидеть, как выглядит быт людей,
					живущих в таких необычных домах. Дополнительная экскурсия по дому на
					воде стоит всего 5 евро с человека.
				</p>

				<p>
					Этот тур — это не просто экскурсия, а возможность получить уникальные
					впечатления, сделать оригинальные фотографии, насладиться
					непринуждённой атмосферой и увидеть Амстердам с самых разных ракурсов.
					И все это — в компании только вашей группы!
				</p>
			</div>
		),
	},
	{
		title: "Это Амстердам детка",
		text: [
			" Отличные места для дегустации, особенности площади Дам и знаменитых кварталов.",
			" Продолжительность: 2 часа.",
		],
		images: [amsterdam1, amsterdam2, amsterdam3],
		additionalInfo: (
			<div>
				<p>
					<strong>Откройте для себя Амстердам с новой стороны:</strong> тур по
					скрытым уголкам города
				</p>

				<p>
					Амстердам — это не только каналы, музеи и ветряные мельницы. В этом
					городе скрыты уголки, о которых знают только местные. Я приглашаю вас
					на уникальный тур, в рамках которого мы откроем для себя самые
					удивительные, но скрытые места Амстердама: старинные церкви, монастыри
					и места с глубоким историческим значением.
				</p>

				<p>Что вас ждет?</p>
				<p>
					Амстердам — это не просто город. Это лабиринт улиц, пропитанных
					тайнами прошлого. Погрузитесь в атмосферу старинных зданий и узких
					улочек, где в тени привычных достопримечательностей скрываются
					настоящие сокровища. В этом туре я расскажу вам не только об известных
					памятниках, но и о тех местах, которые недоступны для большинства
					туристов.
				</p>

				<p>Скрытые церкви</p>
				<p>
					Мы посетим небольшие, но очень интересные церкви, которые скрыты от
					глаз большинства. Эти церкви были построены втайне в период, когда
					католические богослужения в Нидерландах были запрещены. Уникальные
					архитектурные памятники, пропитанные историей и атмосферой уединения.
					Каждое из этих мест хранит свои тайны и моменты, которые перенесут вас
					в другую эпоху.
				</p>

				<p>Монастыри и женские обители</p>
				<p>
					Мы заглянем в монастыри, где когда-то жили монашки, посвятившие свои
					жизни молитвам и заботе о других. Эти исторические здания до сих пор
					хранят атмосферу спокойствия и умиротворения. Вы узнаете, как монашки
					влияли на развитие Амстердама и какую роль играли в культуре и
					обществе города.
				</p>

				<p>Неизвестные уголки Амстердама</p>
				<p>
					Амстердам — это не только туристические маршруты. Мы с вами отправимся
					в уединенные уголки города, где скрыты уютные кафе, интересные галереи
					и ремесленные магазины. Вы увидите, как живут местные жители, и
					сможете почувствовать настоящую атмосферу города.
				</p>

				<p>Почему стоит выбрать этот тур?</p>
				<ul>
					<li>
						<strong>Уникальность:</strong> Этот тур откроет вам такие места,
						которые не встретятся в стандартных экскурсиях. Вы увидите Амстердам
						глазами местных, почувствуете его душу и атмосферу.
					</li>
					<li>
						<strong>История и тайны:</strong> Я расскажу вам истории и мифы,
						которые не найти в путеводителях. Каждое место, которое мы посетим,
						наполнено уникальными событиями и историей, которые оживают, когда
						вы находитесь там.
					</li>
					<li>
						<strong>Индивидуальный подход:</strong> Я лично провожу каждый тур,
						чтобы вы не просто увидели, но и почувствовали Амстердам. Каждая
						экскурсия — это моя любовь к этому городу, и я делюсь ею с вами.
					</li>
				</ul>

				<p>Детали тура:</p>
				<ul>
					<li>Продолжительность: два часа</li>
					<li>Количество участников: до 6 человек</li>
				</ul>

				<p>
					Не упустите шанс увидеть Амстердам с новой стороны! Забронируйте тур
					прямо сейчас и погрузитесь в его тайны. Этот город готов поделиться с
					вами своими самыми глубокими секретами.
				</p>
			</div>
		),
	},
	{
		title: "Уникальная деревня мельниц",
		text: [
			" Уникальная деревня: Занзе Сханс, сырная ферма, музей обуви.",
			" Прекрасные Мельницы: река Заан.",
		],
		images: [mill1, mill2, mill3],
		additionalInfo: (
			<div>
				<p>
					<strong>Занзесханс</strong> — уникальная деревня, где традиции оживают
					в пряничных домиках, сырных фермах и мастерских по производству
					деревянной обуви. Но настоящей жемчужиной являются рабочие мельницы,
					которые излучают необыкновенную красоту, а виды на реку Заан
					невозможно передать словами. Мельница Кат, где производят пигменты для
					красок, откроет вам не только историю, но и захватывающий панорамный
					вид с её смотровой площадки.
				</p>

				<p>
					Экскурсия длится <strong>3 часа</strong>, вход в мельницу —{" "}
					<strong>5 евро</strong>. Стоимость дороги — <strong>8.40 евро</strong>{" "}
					с человека. Группы до 4 человек, идеально для семей с детьми.
				</p>

				<p>
					Вас ждёт множество активностей: от дегустации местных вафелек и сыра
					до участия в мастер-классе по изготовлению обуви. Этот день подарит
					вам массу ярких впечатлений и незабываемые эмоции.
				</p>
			</div>
		),
	},
	{
		title: "Гаага- шкатулка с сокровищами",
		text: ["Тур по Гааге: Магия древности и тайны современности"],
		images: [denhaag1, denhaag2, denhaag3],
		additionalInfo: (
			<div>
				<p>
					<strong>Гаага</strong> — город, который зачарует вас с первого
					взгляда. Здесь, среди старинных зданий и величественных дворцов, можно
					почувствовать дыхание истории, ощутить дух рыцарских времен и найти
					уголки, полные удивительных тайн. Присоединяйтесь ко мне на экскурсию
					по этому необыкновенному городу, который сочетает в себе величие
					прошлого и очарование современности.
				</p>

				<p>
					<strong>Что вас ждет?</strong>
				</p>
				<ul>
					<li>
						<strong>Рыцарские залы и необъятные конюшни</strong> — вы попадете в
						места, где когда-то собирались самые могущественные рыцари, и
						узнаете их секреты. Мы заглянем в древние конюшни, которые хранят
						историю королевских особ.
					</li>
					<li>
						<strong>Сады без лавочек и скрытые сейфы</strong> — эти уединенные
						уголки Гааги полны тайн. Здесь, в тени старинных деревьев и среди
						зелени, можно найти места, о которых знает только местный житель.
					</li>
					<li>
						<strong>Жемчужная сережка в сахарном доме</strong> — в Гааге
						хранится одна из самых удивительных историй. В сахарном доме, что на
						первый взгляд может показаться обычным, скрыта настоящая жемчужина,
						которая хранит часть города в себе.
					</li>
					<li>
						<strong>Аисты-санитары и открытые рты аптек</strong> — Гаага полна
						неожиданных встреч. Прогулка по ее улицам может привести к самым
						необычным, порой даже мистическим открытиям.
					</li>
				</ul>

				<p>
					Этот город удивит вас своими контрастами и увлечет историей, тайнами и
					магией. От старинных рыцарских залов до самых неожиданных уголков —
					Гаага не оставит равнодушным никого.
				</p>

				<p>
					<strong>Длительность тура:</strong> 2 часа
				</p>

				<p>
					Гаага ждёт, чтобы раскрыть вам свои тайны. Присоединяйтесь и влюбитесь
					в этот город с первого взгляда!
				</p>
			</div>
		),
	},
	{
		title: "Фотосессии в старинных костюмах.",
		text: [
			" Фотосессия в старинных костюмах",
			" Дегустация в зале De Drie Fleschjes.",
		],
		images: [century1, century2, century3],
		additionalInfo: (
			<div>
				<p>
					<strong>Тур «Модный Приговор»:</strong> ваш стильный взгляд на
					Амстердам в Золотом веке
				</p>

				<p>
					Этот авторский тур создан для тех, кто ищет что-то уникальное и
					незабываемое в Амстердаме. Прогулка по городу с увлекательными
					рассказами о жизни нидерландцев, моде разных эпох и культуре Золотого
					века плавно перерастает в настоящее историческое приключение.
				</p>

				<p>
					После осмотра лучших уголков Амстердама, вы отправитесь в фотостудию,
					где примерите костюмы эпохи Золотого века. Ощутите себя настоящей
					дамой или кавалером прошлого, ведь каждый образ — это как произведение
					искусства. Вы выберете свой стиль, а мы запечатаем этот момент в одном
					великолепном снимке, который останется с вами навсегда, а еще одно
					фото отправим вам по электронной почте.
				</p>

				<p>
					После весёлой фотосессии мы пригласим вас в исторический зал De Drie
					Fleschjes, где более 365 лет не менялся интерьер, сохраняя атмосферу
					прошлого века. Здесь вы сможете насладиться дегустацией трёх
					уникальных напитков с оригинальными названиями, каждое из которых
					отражает исторические события Амстердама. В процессе дегустации вам
					откроется не только история напитков, но и захватывающие факты о жизни
					Нидерландов в прошлом.
				</p>

				<p>
					Этот тур — не просто путешествие, а целая история, в которой вы —
					главные герои. Индивидуальная экскурсия для 2 человек,
					продолжительностью 3 часа, подарит вам невероятные впечатления и
					стильные воспоминания.
				</p>

				<p>
					<strong>
						Ваш образ, ваше искусство, ваше путешествие во времени!
					</strong>
				</p>
			</div>
		),
	},
	{
		title: "Ван Гог музей",
		text: [
			"Погружение в творчество Ван Гога: экскурсия, раскрывающая его жизнь, стиль и эмоции через картины.",
		],
		images: [vangogh1, vangogh2, vangogh3],
		additionalInfo: (
			<div>
				<p>
					Этот тур — не просто прогулка по музеям, а настоящая обучающая
					экскурсия, которая даст вам ключи для понимания творчества Ван Гога.
					Мы не только расскажем вам о его жизни, но и научим разбираться в его
					картинах, определяя, к какому периоду его творчества относится та или
					иная работа. Вы будете способны не только увидеть, но и почувствовать,
					как менялся стиль художника, какие эмоции и переживания отражаются в
					каждой картине.
				</p>

				<p>
					Особенность этой экскурсии в том, что мы не просто показываем картины,
					но и погружаем в контекст их создания. Мы расскажем о ключевых
					моментах жизни Ван Гога, которые вдохновляли его на создание шедевров,
					а также разберём, как менялась его техника и какие эмоции скрыты за
					каждым мазком.
				</p>

				<p>
					Вы узнаете, что происходило с художником в тот или иной момент жизни,
					и сможете определить его стиль, даже если увидите картину впервые. Это
					не просто фактология, это погружение в мир искусства, которое
					останется с вами надолго.
				</p>

				<p>
					<strong>Продолжительность экскурсии:</strong> 2 часа.{" "}
					<strong>Число участников:</strong> до 4 человек, что позволяет создать
					атмосферу живого общения и глубокого погружения в тему.
				</p>
			</div>
		),
	},
	{
		title: "Делфт",
		text: [
			"История, искусство и уют Делфта в 3-часовой экскурсии с Вермеером и фарфором.",
		],
		images: [delft1, delft2, delft3],
		additionalInfo: (
			<div>
				<p>
					<strong>Делфт</strong> — это не просто живописный город, а настоящий
					центр искусства и истории. Здесь жил и творил великий художник Ян
					Вермеер, чьи картины, такие как “Девушка с жемчужной серёжкой”,
					поражают мир своей утончённой красотой. Прогуливаясь по его родному
					городу, вы сможете почувствовать дух того времени, а на улице Вермеера
					откроются завораживающие виды, которые вдохновляли мастера на создание
					его знаменитых произведений.
				</p>

				<p>
					Но Делфт — это не только искусство, но и знаменитая фарфоровая фабрика
					Делфт, которая производит уникальный синий фарфор. Этот фарфор ценится
					не только среди коллекционеров по всему миру, но и является символом
					королевской семьи Нидерландов. Прогулка по фабрике позволит вам
					увидеть процесс создания этих изысканных изделий и даже приобрести
					частичку этого искусства в подарок.
				</p>

				<p>
					Прогулка по Делфту — это не только знакомство с историей и культурой,
					но и шанс насладиться уютными кафе вдоль каналов, посетить старейший
					рыбный ларёк города и почувствовать атмосферу, которая вдохновляет и
					утешает. Пусть каждый шаг по этим старинным улицам оставит вам
					незабываемые впечатления!
				</p>

				<p>
					<strong>Экскурсия:</strong> для 1-4 человек,{" "}
					<strong>продолжительность:</strong> 3 часа. Идеально подходит для
					семей с детьми, ведь этот город будет интересен каждому!
				</p>
			</div>
		),
	},
	{
		title: "Кекенхоф: Цветы, Искусство, Незабываемый Опыт.",
		text: [" Кекенхоф: 32 га тюльпанов.", " 800+ сортов цветов."],
		images: [flower1, flower2, flower3],
		additionalInfo: `Кекенхоф, известный как самый красивый весенний парк, удивляет 32 гектарами тюльпанов и цветов. Парк проложен 15-километровой пешеходной дорожкой, ведущей к водоемам, фонтанам и скульптурам. С 800+ сортами цветов, дети наслаждаются игровой площадкой Miffy и веселой охотой за сокровищами.

Расположенный в сердце Болленстрика, Кекенхоф предлагает виды на поля с тюльпанами, нарциссами и гиацинтами. Парк украшен арт-объектами художников и создан оригинальным дизайном отцом и сыном Зохером в 1865 году.

Туры для 1-6 человек с минивеном, длительностью 5 часов (с дорогой), вход не включен в стоимость. Предлагается ценовая политика без машины.`,
	},
	{
		title: "Человек пароход. Прогулка на лодке",
		text: [
			" Прогулка по каналам: закуски.",
			" Виды с лодки: уникальные детали Амстердама.",
		],
		images: [canalboat1, canalboat2, canalboat3],
		additionalInfo: (
			<div>
				<p>
					<strong>Уникальная прогулка по каналам Амстердама:</strong> Ваш
					идеальный способ увидеть город с воды
				</p>

				<p>
					Если у вас есть особенное событие или просто желание провести время в
					необычной обстановке, наша прогулка по каналам Амстердама — это то,
					что вам нужно. Представьте, как вы плывете по историческим водным
					артериям города, наслаждаясь фантастическими видами, которые
					раскрывают перед вами все самые мелкие детали — от старинных зданий до
					скрытых уголков Амстердама.
				</p>

				<p>
					Вы можете взять с собой закуски и напитки, а также при желании —
					оформить лодку в тематическом стиле. Открытая лодка идеально подходит
					для того, чтобы полностью погрузиться в атмосферу, ощутить вдыхание
					свежего воздуха и увидеть все детали города, которые скрываются от
					глаз с обычных улиц. Водные полицейские и правила движения — все будет
					объяснено для вашего комфорта и безопасности, а для самых маленьких
					участников тура — есть возможность попробовать управлять лодкой!
				</p>

				<p>
					Наш гид-капитан — настоящий знаток Амстердама, который поделится с
					вами увлекательными историями о каналах и многих других секретах
					города. Вы не просто увидите город с воды, но и откроете для себя его
					уникальные исторические моменты.
				</p>

				<p>
					<strong>Продолжительность прогулки:</strong> 2 часа,{" "}
					<strong>стоимость:</strong> 250 евро для группы до 6 человек. А если
					вы захотите продлить своё удовольствие, дополнительные часы — всего
					100 евро/час.
				</p>

				<p>
					Не упустите шанс сделать ваш визит в Амстердам по-настоящему
					незабываемым!
				</p>
			</div>
		),
	},

	{
		title: "А какой он ночью ?",
		text: [
			" Необычное путешествие от церкви.",
			" Запретные места, морская традиция.",
		],
		images: [nightamsterdam1, nightamsterdam2, nightamsterdam3],
		additionalInfo: `Приглашаю в увлекательное путешествие! Начнем от церкви, но маршрут будет необычным. Мы заглянем в места, где обычно не разрешают заходить. Одна из задач — выпить, как моряки делали это традиционно. Позже узнаем, в чем разница между разными магазинами. Будем находиться в местах, где традиционно курят трубку мира. А затем нас ждут странные шоу и красные окна. А завершим мы это удивительное приключение в баре старого моряка. Там нас ждет кружка пива и история о том, зачем морякам были нужны вшивые обезьянки.`,
	},
	{
		title: "Вкусный тур",
		text: [" Гастрономический город", " Рестораны, уличная еда."],
		images: [tasty1, tasty2, tasty3],
		additionalInfo: (
			<div>
				<p>
					<strong>Гастрономический тур по Амстердаму:</strong> открой вкус
					города!
				</p>

				<p>
					Амстердам — это не только каналы и мосты, но и удивительная кулинарная
					культура. Присоединяйтесь ко мне на гастрономический тур, где мы
					откроем для себя не только вкусные блюда, но и уникальные места,
					которые редко посещают туристы. Вместе попробуем лучшие голландские
					деликатесы и узнаем об их истории.
				</p>

				<p>
					<strong>Что вас ждет?</strong>
				</p>
				<ul>
					<li>
						<strong>Яблочный пирог в королевском ресторане</strong> — начнем с
						классического голландского десерта. Ароматный яблочный пирог в одном
						из самых атмосферных ресторанов города подарит вам настоящее
						наслаждение.
					</li>
					<li>
						<strong>Дегустация сыров в знаменитой сыроварне</strong> — попробуем
						лучшие сыры Нидерландов: гауду, эдам и другие. Я расскажу о процессе
						их производства и истории голландской сыроварни.
					</li>
					<li>
						<strong>Селедка у селедочника второго поколения</strong> — один из
						самых популярных голландских деликатесов. Мы попробуем свежую
						селедку с луком и маринованными огурчиками у местного продавца,
						который продолжает семейное дело.
					</li>
					<li>
						<strong>Крокеты в старой столовой</strong> — традиционное
						голландское блюдо. В старинной столовой Амстердама мы насладимся
						хрустящими крокетами, которые здесь готовят по старинным рецептам.
					</li>
					<li>
						<strong>Пиво в обезьяньем баре</strong> — посетим необычный бар, где
						подают не только традиционное пиво, но и уникальные сорта. Атмосфера
						этого места — незабываемая!
					</li>
					<li>
						<strong>Трактир Wynand Fockink</strong> — заглянем в этот
						легендарный трактир, который является одним из старейших в
						Амстердаме. Здесь можно попробовать традиционные голландские напитки
						и ликеры, а также ощутить атмосферу старинного Амстердама.
					</li>
					<li>
						<strong>Подборка кафе и ресторанов</strong> — в конце тура я
						поделюсь с вами лучшими местами для продолжения гастрономического
						путешествия по Амстердаму. Рестораны и кафе, которые стоит посетить,
						если вы хотите попробовать больше!
					</li>
				</ul>

				<p>
					<strong>Что вас ждет в туре?</strong>
				</p>
				<ul>
					<li>
						<strong>Уникальные места и блюда:</strong> попробуем блюда, которые
						редко можно найти в туристических путеводителях, и откроем секреты
						местной кухни.
					</li>
					<li>
						<strong>Голландская культура еды:</strong> я расскажу о традициях
						голландской кухни, о том, как еда отражает культуру и образ жизни
						города.
					</li>
					<li>
						<strong>Рекомендации и бронирования:</strong> помогу с бронированием
						в лучших ресторанах и кафе, чтобы ваш гастрономический опыт был
						комфортным и насыщенным.
					</li>
				</ul>

				<p>
					<strong>Продолжительность тура:</strong> 3 часа
				</p>

				<p>Не упустите шанс открыть Амстердам с гастрономической стороны!</p>
			</div>
		),
	},
	{
		title: "Дегустация в старом трактире.",
		text: [
			" Дегустация в зале Золотого века.",
			" История, напитки, атмосфера.",
		],
		images: [tasting1, tasting2, tasting3],
		additionalInfo: `После фотосессии вы отправитесь в дегустационный зал De Drie Fleschjes, который восходит к Золотому веку. Этот зал существует уже 365 лет, и его интерьер почти не изменился. Здесь вы найдете классический шарман и коллекцию бутылок мэра. Вы сможете попробовать 3 напитка, такие как 'Экскурсия по церкви' или 'Саскии' (жены Рембрандта). Я также расскажу вам о истории и напитках того времени.`,
	},
	{
		title: "По Амстердаму играючи: прогулка-квест.",
		text: [" Игровое изучение города.", " Интересный, интерактивный тур."],
		images: [quest1, quest2, quest3],
		additionalInfo: (
			<div>
				<p>
					<strong>Готовы открыть город с новой стороны?</strong> Вместе мы
					отправимся в увлекательное путешествие, которое раскроет тайны и
					загадки, скрытые в его улочках и зданиях. Я подготовил для вас серию
					головоломок и испытаний, которые приведут нас к самым уникальным
					местам и историческим сокровищам.
				</p>

				<p>
					Задания будут не из лёгких, но ведь именно это делает их
					захватывающими: переплыть реку, попасть на фильм без просмотра, найти
					здание, стоящее на 8000 сваях, оказаться рядом с плачущей башней,
					отыскать дома по таинственным табличкам, взглянуть на здание, что
					пережило пожар, попробовать сыр с лавандой, увидеть красный огонёк в
					окне и пройтись по кладбищу, не испугавшись.
				</p>

				<p>
					Все это — не просто приключение, а шанс узнать город за 3 часа лучше,
					чем за целый день. Не забывайте, что самый энергичный участник получит
					особенный нидерландский приз!
				</p>

				<p>
					Если нас будет меньше 6 человек, мы легко трансформируем квест в
					динамичную обзорную экскурсию — ведь веселье и интрига важнее всего.
					Подробности об альтернативных маршрутах можно обсудить позже, но одно
					ясно: скучно не будет!
				</p>
			</div>
		),
	},
	{
		title: "Прогулка на велосипедах, исследуя Амстердам вместе.",
		text: [" Велосипеды: каналы, музеи", " Экскурсия: история, гастрономия."],
		images: [bike1, bike2, bike3],
		additionalInfo: (
			<div>
				<p>
					<strong>Вело тур по Амстердаму:</strong> откройте город с велосипеда!
				</p>

				<p>
					Амстердам — город, который лучше всего исследовать на велосипеде!
					Присоединяйтесь ко мне на увлекательный велотур, который откроет перед
					вами не только главные достопримечательности, но и скрытые уголки
					города. Мы будем кататься по каналам, мимо исторических памятников,
					уникальных рынков и старинных зданий. Это шанс увидеть Амстердам с
					уникальной точки зрения и узнать о нем больше, чем расскажут
					путеводители!
				</p>

				<p>
					<strong>Что вас ждет?</strong>
				</p>
				<ul>
					<li>
						<strong>Центральный вокзал</strong> — отправная точка нашего
						путешествия. С него мы стартуем и сразу погружаемся в атмосферу
						Амстердама, проезжая по живописным каналам и мостам.
					</li>
					<li>
						<strong>Крупнейший научный музей в Нидерландах</strong>. Мы
						остановимся у этого невероятного музея, который славится своими
						уникальными выставками. Для любителей науки и технологий это будет
						настоящим открытием!
					</li>
					<li>
						<strong>Корабль 18 века</strong>. Этот исторический корабль
						расскажет о морской мощи Голландии и станет ярким моментом
						путешествия. Прогулка по нему — это как путешествие во времени!
					</li>
					<li>
						<strong>Средневековые истории на площади</strong>. Прогуляемся по
						старинным уголкам Амстердама, где я поделюсь увлекательными
						рассказами о жизни в средневековый период и раскрою тайны древних
						улиц.
					</li>
					<li>
						<strong>Самый большой блошиный рынок в Нидерландах</strong> —
						настоящее место для охотников за сокровищами! Вы найдете здесь всё —
						от антиквариата до уникальных современных товаров.
					</li>
					<li>
						<strong>Цветочный рынок</strong> — это не просто рынок, а часть
						амстердамской культуры, где продаются свежие цветы, знаменитые
						тюльпаны и другие голландские растения.
					</li>
					<li>
						<strong>
							Площадь Рембрандта и знаменитая бронзовая инсталляция “Ночной
							дозор”
						</strong>{" "}
						— расскажу вам об этом шедевре искусства и его значении в мировой
						культуре.
					</li>
					<li>
						<strong>Площадь Дам</strong>, где расположены иконичный дворец на 14
						000 сваях и старейшая церковь с великолепным органом.
					</li>
					<li>
						<strong>Монастырь</strong> — уникальное место, где я расскажу об
						историческом религиозном движении, которое оставило свой след в
						жизни города.
					</li>
				</ul>

				<p>
					Завершение тура — мы вернемся на Центральный вокзал, где сможете
					вернуть велосипеды и поделиться впечатлениями.
				</p>

				<p>
					<strong>Почему стоит выбрать этот тур?</strong>
				</p>
				<ul>
					<li>
						<strong>Необычный маршрут:</strong> вы увидите Амстердам с самых
						разных сторон — от величественных музеев и исторических памятников
						до уютных уличек и скрытых уголков.
					</li>
					<li>
						<strong>Незабываемая атмосфера:</strong> почувствуйте себя местным
						жителем, исследуя город, как это делают амстердамцы — на велосипеде!
					</li>
					<li>
						<strong>Идеально для всех:</strong> тур подходит для всех возрастов
						и интересов. Это отличный способ провести день с семьей или
						друзьями, или открыть город по-новому.
					</li>
				</ul>

				<p>
					<strong>Продолжительность тура:</strong> 3 часа
				</p>

				<p>
					Не упустите шанс увидеть Амстердам с велосипеда! Присоединяйтесь ко
					мне, и давайте сделаем это приключение незабываемым!
				</p>
			</div>
		),
	},
	{
		title: "Роттердам ультра современный город порт",
		text: ["Роттердам — город, который вдохновляет"],
		images: [rotterdam1, rotterdam2, rotterdam3],
		additionalInfo: (
			<div>
				<p>
					<strong>Роттердам</strong> — это город, в котором гармонично
					сочетаются современность и история, а его уникальная архитектура
					поражает воображение. Здесь каждый уголок наполнен новыми
					впечатлениями: от перевернутых кубических домов до старинных маяков в
					порту. В Роттердаме можно почувствовать дух прогресса и свободы, ведь
					город всегда был и остается в поиске новых форм и идей.
				</p>

				<p>
					<strong>Что нас ждет?</strong>
				</p>
				<ul>
					<li>
						<strong>Кубические дома.</strong> Эти необычные здания,
						расположенные в центре города, выглядят как перевернутые кубики.
						Погрузившись в их интерьер, вы почувствуете, как нестандартные формы
						меняют восприятие пространства. Это не просто архитектура — это
						искусство, олицетворение прогрессивных идей.
					</li>
					<li>
						<strong>Музей старинных кораблей.</strong> В старинной гавани
						Роттердама находится музей, который переносит нас в эпоху великих
						морских открытий. Здесь можно увидеть настоящие старинные суда,
						которые когда-то бороздили моря и океаны. История, пропитанная
						соленым воздухом, раскрывает важнейшие моменты прошлого города,
						ставшего крупным портом мира.
					</li>
					<li>
						<strong>Уникальный фудкорт.</strong> Роттердам — это не только
						архитектура, но и гастрономия. Один из самых оригинальных фудкортов
						города предлагает сочетание мировых кухонь в одном месте. Прогулка
						по этому рынку — это настоящее путешествие вкусов, от свежих
						морепродуктов до экзотических блюд, готовых удивить.
					</li>
					<li>
						<strong>Единственная церковь в центре города.</strong> В центре
						Роттердама расположена уникальная церковь, пережившая Вторую мировую
						войну. Она — один из немногих сохранившихся памятников архитектуры,
						которые уцелели после бомбардировок. Прогулка по этой церкви — это
						как шаг в прошлое, где время словно остановилось.
					</li>
				</ul>

				<p>
					<strong>Роттердам</strong> — это не просто город, это место, где
					история и современность встречаются на каждом шагу. Водное такси,
					прогулки по каналам, запах свежего угря и селедки на каждом углу,
					гигантские ступни, напоминающие о корнях города — Роттердам удивляет,
					вдохновляет и заставляет смотреть на мир под новым углом.
				</p>
			</div>
		),
	},
	{
		title: "Персональная яхта.",
		text: [
			" Приватная 2-часовая прогулка.",
			" Остров Пампус: старинный морской форт мистической красоты.",
		],
		images: [boat1, boat2, boat3],
		additionalInfo: `Если важное событие на подходе и хочется провести время с друзьями, предлагаем уникальную возможность: приватная 2-часовая прогулка на острове Пампус. Это загадочное место – искусственный остров с морским фортом, частью оборонительной системы возле Алмере.

Форт, начатый в 1887 году и сданный в эксплуатацию через 8 лет, служил обороной входа в гавань. Спустя время форт передали гражданским властям, отреставрировали и открыли для посещений. Большая часть помещений сохранила оригинальную обстановку, привлекая туристов.

Волонтёры устраивают квесты на пиратскую тему, привлекая более 40 тысяч искателей приключений ежегодно. Однако, большая часть боевой артиллерии была убрана с острова по королевскому приказу.`,
	},
];

export default cardData;
